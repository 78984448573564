import { AxiosError } from 'axios'

export const dataMaskaTokens = JSON.stringify({
  '#': { pattern: '[0-9]' },
  'N': { pattern: '[0-9a-zA-Z]' },
  'A': { pattern: '[a-zA-Z]' },
})

export function isStrongPassword(v: string) {
  return v.length >= 8 && /^(?=.*[A-Z])(?=.*[^0-9a-zA-Z ])(?=.*[0-9])/.test(v)
}

export function isValidName(name: string) {
  const validNamePattern = /^[a-zA-ZÀ-ÿ\\u00f1\\u00d1]+(?:[-'\s][a-zA-ZÀ-ÿ\\u00f1\\u00d1]+)*$/
  if (validNamePattern.test(name)) {
    return true
  }
  return false
}

export function satisfiesRegex(str: string, regex: RegExp) {
  const valid = regex.test(str)
  return valid
}

export const getDataOnboarding = ({ userMcId = '', key = '' }) => {
  const authStore = useAuthStore()
  const userMetadata = authStore.userMetadata
  if (!userMcId || !key) return ''
  try {
    const dataOnboarding = window.localStorage.getItem(`${userMcId}-data-onboarding`)
    if (userMetadata[key]) {
      return userMetadata[key] ?? ''
    } else if (dataOnboarding) {
      return JSON.parse(dataOnboarding)[key]
    }
    return ''
  } catch (e) {
    return ''
  }
}

type CpfDuplicatedErrorResponse = {
  errors: {
    'metadata.cpf'?: string
  }
}

export function getCpfOwnerEmailFromError(maybeError: AxiosError | unknown) {
  if (!maybeError) return undefined
  const error = maybeError as AxiosError
  const errorData = error?.response?.data as CpfDuplicatedErrorResponse
  if (!errorData) return undefined
  const cpfData: string | undefined = errorData.errors['metadata.cpf']
  if (!cpfData) return undefined
  const regex = /#([a-zA-Z0-9._+*]+@[a-zA-Z0-9._+*]+\.[a-zA-Z]{2,})(\s+|$)/
  const match = regex.exec(cpfData)
  const email = match ? match[1] : undefined
  return email
}
